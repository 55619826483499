// Styles
// Import Font Awesome Icons Set
@import '~font-awesome/css/font-awesome.min.css';
// Import Main styles for this application
@import './scss/style.scss';

.blockTitle {
    font-size: 1.5em
}

.blockTitleBold {
    font-size: 2.5em;
    font-weight: bold
}